// 
// _card.scss
// 

.card {
  margin-bottom: 16px;
  background-color: $card-bg;
  border: 1px solid $border;
  box-shadow: $card-shadow;
  .card-header{
    background-color: $card-bg;
    border-bottom: 1px solid $border;
    &:first-child {
      border-radius: calc(.5rem - 1px) calc(.5rem - 1px) 0 0;
    }
  }
  .card-footer{
    background-color: $card-bg;
    border-top: 1px dashed $border;
  }
}
.card-title {
  text-transform: capitalize;
  letter-spacing: 0.02em;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: $gray-700;
}


